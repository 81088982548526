import React from 'react'
import Banner from '../components/Banner'
import Analytics from '../components/Analytics'
import History from '../components/History'

// import Helpbtn from '../components/Helpbutton/Helpbutton/Helpbtn'
import "../assets/CSS/style.css"
// import InfoPopup from '../components/Popup'

const Home = () => {
  return (
    <>
    {/* <InfoPopup /> */}
      <Banner />
      
      <Analytics />
      {/* <History /> */}

      {/* <Helpbtn /> */}
    </>
  )
}

export default Home