import { useAccount, useNetwork, useContractRead, useContractWrite, useContractReads, usePrepareContractWrite, readContract, readContracts } from 'wagmi'
import { DWCAddress, USDTAddress, apiSecret, defaultNetwork, swapContract } from '../config'
import SwapAbi from "../abis/SwapABI.json"
import ERCAbi from "../abis/erc20.json"
import { useCallback, useEffect, useMemo, useState } from 'react'
import { parseUnits, formatUnits, maxUint256, parseEther, formatEther } from 'viem'
import { toast } from "react-hot-toast"
import { bsc } from 'wagmi/chains'
import { ethers } from 'ethers'
import { ContractExecutionError } from 'web3'
import { useSwapAccount } from './useSwapAccount'

export const Approve = (tokenAdd) => {
    const { address } = useAccount()
    const { config } = usePrepareContractWrite({
        address: tokenAdd,
        abi: ERCAbi,
        functionName: 'approve',
        args: [swapContract, maxUint256],
        enabled: Boolean(address && tokenAdd),
    })

    const { write: approve, isLoading, isSuccess, isError, data } = useContractWrite({
        ...config,
        onError: (error) => {
            toast.error(error.shortMessage.split(":").pop().trim())
        }
    })

    const execute = useCallback(async () => {
        try {
            if (!approve) throw new Error('Failed to prepare approval transaction')
            await approve()
            return true
        } catch (err) {
            toast.error(err.shortMessage)
            return null
        }
    }, [approve])

    return { execute, isLoading, isSuccess, isError, data }
}

export const CheckUser = () => {
    const { address } = useAccount()
    const { chain } = useNetwork()
    const isCorrectNetwork = chain?.id === defaultNetwork
    const [canSwap, setCanSwap] = useState(false)

    // Read blacklist status
    const { data: isBlacklisted } = useContractRead({
        address: swapContract,
        abi: SwapAbi,
        functionName: 'isBlacklisted',
        args: [address],
        enabled: Boolean(address && isCorrectNetwork),
        watch: true,
    })

    // Write function to update blacklist
    const { config: blacklistConfig } = usePrepareContractWrite({
        address: swapContract,
        abi: SwapAbi,
        functionName: 'updateBlacklist',
    })

    const { write: updateBlacklist } = useContractWrite(blacklistConfig)

    useEffect(() => {
        if (isBlacklisted !== undefined) {
            setCanSwap(!isBlacklisted)
        }
    }, [isBlacklisted])

    const updateBlacklistStatus = useCallback(async (userAddress, status) => {
        try {
            if (!updateBlacklist) throw new Error('Failed to prepare blacklist update')
            await updateBlacklist({ args: [userAddress, status] })
            return true
        } catch (error) {
            console.error("Error updating blacklist status:", error)
            return false
        }
    }, [updateBlacklist])

    return { canSwap, updateBlacklistStatus }
}

export const Swap = (tokenIn, tokenOut, amountIn) => {
    const { address } = useAccount()

    const { write: executeSwap, isLoading, isSuccess, isError, error, data } = useContractWrite({
        address: swapContract,
        abi: SwapAbi,
        functionName: 'swap',
        args: [tokenIn, tokenOut, parseEther(amountIn.toString())],
        onError: (error) => {
            toast.error(error.shortMessage.split(":").pop().trim())
        }
    })

    const execute = useCallback(async () => {
        try {
            if (!executeSwap) throw new Error('Failed to prepare swap transaction')
            
            executeSwap()
            return true
        } catch (err) {
            return null
        }
    }, [executeSwap])

    // Format error message for UI display
    const formattedError = useMemo(() => {
        if (!error) return null
        
        // Extract just the revert reason from the full error message
        if (typeof error.message === 'string') {
            const match = error.message.match(/reason: ([^Contract]+)/);
            console.log(match)
            if (match && match[1]) {
                return match[1].trim();
            }
        }
        
        // Fallback to other error formats if needed
        if (error.reason) return error.reason;
        if (error.data?.message) return error.data.message;
        return error.message || 'Unknown error occurred';
    }, [error]);

    return { execute, isLoading, isSuccess, isError, error: formattedError, data }
}

// export const Conversion = () => {
//     const [adminFees, setadminFees] = useState(0)

//     return { ZebToUsdt, UsdtToZeb, adminFees }
// }

export const getSwapAmounts = async (tokenInAddress, tokenOutAddress, amountIn) => {
    const functionName = 'getSwapAmounts'
    
    const contract = {
        address: swapContract,
        abi: SwapAbi,
        functionName,
        args: [tokenInAddress, tokenOutAddress, parseEther(amountIn.toString())],
    }

    try {
        const data = await readContracts({ contracts: [contract] })
        // console.log(data)
        return {
            amountOut: formatUnits(data[0].result?.[0] || 0n, 18),
            fee: formatUnits(data[0].result?.[1] || 0n, 18)
        }
    } catch (error) {
        console.error('Error getting swap amounts:', error)
        // throw error
        return {
            amountOut: '0',
            fee: '0'
        }
    }
}

export function Alldata() {
    const { address } = useSwapAccount()

    // console.log(address);

    const contracts = [
        {
            address: DWCAddress,
            abi: ERCAbi,
            functionName: 'allowance',
            args: [address, swapContract],
        },
        {
            address: DWCAddress,
            abi: ERCAbi,
            functionName: 'balanceOf',
            args: [address],
        },
        {
            address: USDTAddress,
            abi: ERCAbi,
            functionName: 'allowance',
            args: [address, swapContract],
        },
        {
            address: USDTAddress,
            abi: ERCAbi,
            functionName: 'balanceOf',
            args: [address],
        },
        {
            address: swapContract,
            abi: SwapAbi,
            functionName: 'maxDailySwapAmount',
            args: [],
        },
        {
            address: swapContract,
            abi: SwapAbi,
            functionName: 'userDailyVolume',
            args: [address],
        }
    ]

    const { data, isError, isLoading } = useContractReads({
        contracts,
        enabled: true,
        watch: true,
        select: (data) => ({
            zebAllowance: parseFloat(formatUnits(data[0].result || 0n, 18)),
            zebBalance: formatUnits(data[1].result || 0n, 18),
            usdtAllowance: parseFloat(formatUnits(data[2].result || 0n, 18)),
            usdtBalance: formatUnits(data[3].result || 0n, 18),
            maxDailySwapAmount: parseFloat(formatUnits(data[4].result || 0n, 18)),
            userDailyVolume: parseFloat(formatUnits(data[5].result || 0n, 18)),
        }),
    })

    return useMemo(() => data || { zebAllowance: '0', zebBalance: '0', usdtAllowance: '0', usdtBalance: '0', maxDailySwapAmount: '0', userDailyVolume: '0' }, [data])
}

export function SwapRate() {
    const [tradeVol, settradeVol] = useState(0)
    const [tokenLiquidity, settokenLiquidity] = useState([])

    const contracts = [
        {
            address: swapContract,
            abi: SwapAbi,
            functionName: 'priceInfo',
            args: [],
        },
        {
            address: DWCAddress,
            abi: ERCAbi,
            functionName: 'balanceOf',
            args: [swapContract],
        },
        {
            address: USDTAddress,
            abi: ERCAbi,
            functionName: 'balanceOf',
            args: [swapContract],
        },
        {
            address: swapContract,
            abi: SwapAbi,
            functionName: 'adminFeeRate',
            args: [],
        }
    ]

    const { data } = useContractReads({
        contracts,
        watch: true,
        select: (data) => ({
            sellPrice: parseFloat(formatUnits(data[0]?.result?.[1] || 0n, 18)),
            buyPrice: (1/parseFloat(formatUnits(data[0]?.result?.[0] || 0n, 18))),
            zebBalance: parseFloat(formatUnits(data[1].result || 0n, 18)),
            usdtBalance: parseFloat(formatUnits(data[2].result || 0n, 18)),
            adminFeeRate: (data[3].result?.toString() || 0)/ 10000,
        })
    })

    // console.debug(data)

    // useEffect(() => {
    //     const fetchVolume = async () => {
    //         try {
    //             const response = await fetch('https://defi-node.vercel.app/get_vol', {
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                     'Authorization': `Bearer${apiSecret}`
    //                 },
    //             })
    //             const data = await response.json()
    //             settradeVol(data[0]?.amount)
    //         } catch (error) {
    //             console.error("Error fetching volume:", error)
    //         }
    //     }

    //     const fetchTokenLiquidity = async () => {
    //         try {
    //             const response = await fetch('https://defi-node.vercel.app/get_tokenLiq', {
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                     'Authorization': `Bearer${apiSecret}`
    //                 },
    //             })
    //             const data = await response.json()
    //             settokenLiquidity(data[0]?.tokenLiquidity)
    //         } catch (error) {
    //             console.error("Error fetching token liquidity:", error)
    //         }
    //     }

    //     const interval = setInterval(() => {
    //         fetchVolume()
    //         fetchTokenLiquidity()
    //     }, 12000)

    //     fetchVolume()
    //     fetchTokenLiquidity()

    //     return () => clearInterval(interval)
    // }, [])

    return { ...data }
}

export function AdminFunction() {
    const { address } = useAccount()

    // Contract writes for token operations
    const { writeAsync: writeBlacklist } = useContractWrite({
        address: swapContract,
        abi: SwapAbi,
        functionName: 'updateBlacklist',
    })
    const { writeAsync: writeDWC } = useContractWrite({
        address: DWCAddress,
        abi: ERCAbi,
        functionName: 'transfer'
    })

    const { writeAsync: writeUSDT } = useContractWrite({
        address: USDTAddress, 
        abi: ERCAbi,
        functionName: 'transfer'
    })

    // Contract writes for swap operations
    const { writeAsync: writeWithdraw } = useContractWrite({
        address: swapContract,
        abi: SwapAbi,
        functionName: 'withdraw'
    })

    const { writeAsync: writeEmergencyWithdraw } = useContractWrite({
        address: swapContract,
        abi: SwapAbi,
        functionName: 'emergencyWithdraw'
    })

    const { writeAsync: writeAdminFee } = useContractWrite({
        address: swapContract,
        abi: SwapAbi,
        functionName: 'updateAdminFee'
    })

    const { writeAsync: writeFeeAddress } = useContractWrite({
        address: swapContract,
        abi: SwapAbi,
        functionName: 'updateFeeCollector'
    })

    const { writeAsync: writeSwapLimit } = useContractWrite({
        address: swapContract,
        abi: SwapAbi,
        functionName: 'updateSwapLimits'
    })

    const { writeAsync: writeDWCRate } = useContractWrite({
        address: swapContract,
        abi: SwapAbi,
        functionName: 'updateSellPrice'
    })

    const { writeAsync: writeUSDTRate } = useContractWrite({
        address: swapContract,
        abi: SwapAbi,
        functionName: 'updateBuyPrice'
    })

    // Add liquidity handler
    const AddLiquidity = useCallback(async (token, amount) => {
        try {
            const isCoin = token.toLowerCase() === DWCAddress.toLowerCase()
            const decimalPlaces = isCoin ? 18 : 18
            const convertAmount = parseUnits(amount, decimalPlaces)
            
            const writeFunction = isCoin ? writeDWC : writeUSDT
            
            const tx = await writeFunction({
                args: [swapContract, convertAmount]
            })
            
            toast.success("Liquidity Added Successfully")
            return true

        } catch (err) {
            toast.error(err.shortMessage)
            return null
        }
    }, [writeDWC, writeUSDT])

    // Emergency withdraw handler
    const EmergencyWithdraw = useCallback(async (token, amount) => {
        try {
            const convertAmount = parseUnits(amount, token === DWCAddress ? 18 : 18)
            
            const tx = await writeWithdraw({
                args: [token, convertAmount]
            })

            toast.success("Emergency Withdrawal Successful")
            return true

        } catch (err) {
            toast.error(err.shortMessage)
            return null
        }
    }, [writeEmergencyWithdraw])

    // Return object with all admin functions
    return {
        AddLiquidity,
        EmergencyWithdraw,
        
        EmergencyWithdrawAll: async (token) => {
            try {
                const tx = await writeEmergencyWithdraw({
                    args: [token]
                })
                toast.success("Emergency Withdrawal All Successful")
                return true
            } catch (err) {
                toast.error(err.shortMessage)
                return null
            }
        },

        ChangeAdminFee: async (amount) => {
            if (amount < 0.001) {
                toast.error("Min Amount 0.001")
                return true
            }
            try {
                const tx = await writeAdminFee({
                    args: [(amount.toString() / 100) * 10000]
                })
                toast.success("Admin Fee Changed Successfully")
                return true
            } catch (err) {
                toast.error(err.shortMessage)
                return null
            }
        },

        ChangeFeeAddress: async (address) => {
            try {
                const tx = await writeFeeAddress({
                    args: [address]
                })
                toast.success("Fee Address Updated Successfully")
                return true
            } catch (err) {
                toast.error(err.shortMessage)
                return null
            }
        },

        ChangePerSwapMaxLimit: async (limit) => {
            try {
                const tx = await writeSwapLimit({
                    args: [parseUnits(limit.toString(), 18)]
                })
                toast.success("Swap Limit Updated Successfully")
                return true
            } catch (err) {
                toast.error(err.shortMessage)
                return null
            }
        },

        setZebSwapRate: async (rate) => {
            try {
                const tx = await writeDWCRate({
                    args: [parseUnits(rate.toString(), 18)]
                })
                toast.success("DWC Swap Rate Updated Successfully")
                return true
            } catch (err) {
                toast.error(err.shortMessage)
                return null
            }
        },

        setUsdtSwapRate: async (rate) => {
            try {
                const tx = await writeUSDTRate({
                    args: [parseUnits(rate.toString(), 18)]
                })
                toast.success("USDT Swap Rate Updated Successfully")
                return true
            } catch (err) {
                toast.error(err.shortMessage)
                return null
            }
        },
        setBlacklist: async (address, status) => {
            try {
                const tx = await writeBlacklist({
                    args: [address, status]
                })
                toast.success("Blacklist Updated Successfully")
                return true
            } catch (err) {
                console.log(err)
                toast.error(err.shortMessage)
                return null
            }
        }
    }
}

export function AnalyticsData() {
    const { address } = useAccount()

    const contracts = [
        {
            address: swapContract,
            abi: SwapAbi,
            functionName: 'feeCollector',
        },
        {
            address: swapContract,
            abi: SwapAbi,
            functionName: 'maxDailySwapAmount',
        },
        {
            address: swapContract,
            abi: SwapAbi,
            functionName: 'isHandler',
            args: [address],
        }
    ]

    const { data } = useContractReads({
        contracts,
        watch: true,
        select: (data) => ({
            feeAddress: data[0]?.result,
            perSwapLimit: parseFloat(formatEther(data[1]?.result || 0n)),
            isHandler: data[2]?.result,
        }),
    })

    return data || { adminFees: '0', feeAddress: '', perSwapLimit: 0, isHandler: false }
}

